@use "@littlemissrobot/sass-system" as _system;
@use "../settings" as settings;

// =============================================================================
// Block
// =============================================================================
.c-button {
	@include _system.typo(paragraph, md);

	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: _system.space(2) _system.space(6);

	background-color: _system.color(interactive, primary, base);
	color: _system.color(text, inverse);

	transition: background-color settings.$animation_base,
		color settings.$animation_base;

	@include _system.at("7") {
		display: inline-flex;
		width: auto;
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-button__icon {
	margin-left: _system.space(4);

	transition: transform settings.$animation_base;
}

// =============================================================================
// Modifiers
// =============================================================================
.c-button--inverse {
	background-color: _system.color(interactive, inverse, base);
	color: _system.color(interactive, primary, base);
}

.c-button--secondary {
	background-color: _system.color(interactive, secondary, base);
}

.c-button--inline {
	display: inline-flex;
	width: auto;
}

// =============================================================================
// States
// =============================================================================
.c-button {
	&:hover,
	&:focus {
		background-color: _system.color(interactive, primary, hover);

		.c-button__icon {
			transform: translateX(_system.space(2));
		}
	}
}

.c-button--secondary {
	&:hover,
	&:focus {
		background-color: _system.color(interactive, secondary, hover);
	}
}

.c-button--inverse {
	&:hover,
	&:focus {
		color: _system.color(interactive, primary, hover);
		background-color: _system.color(interactive, inverse, hover);
	}
}
