@use "@littlemissrobot/sass-system" as _system;
@use "../settings" as settings;

// =============================================================================
// Block
// =============================================================================
.c-nav {
	display: flex;
	flex-direction: column;

	@include _system.at("9") {
		flex-direction: row;
		align-items: center;
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-nav__list {
	display: flex;
	flex-direction: column;

	@include _system.at("9") {
		flex-direction: row;
	}
}

.c-nav__item {
	display: flex;
	align-items: center;

	@include _system.to("9") {
		width: 100%;
	}

	& + .c-nav__item {
		margin-top: _system.space(4);

		@include _system.at("9") {
			margin-top: 0;
			margin-left: _system.space(8);
		}
	}
}

.c-nav__link {
	@include _system.typo(title, lg);

	display: flex;
	align-items: center;

	color: _system.color(text, inverse);
	background-image: linear-gradient(currentColor, currentColor);
	background-position: 0% 100%;
	background-repeat: no-repeat;
	background-size: 0% _system.rem(2px);

	transition: background-size settings.$animation_base,
		color settings.$animation_base;

	@include _system.at("9") {
		@include _system.typo(paragraph, lg, base);

		background-size: 0% _system.rem(1px);
	}
}

.c-nav__text {
	@include _system.typo(paragraph, lg, base);

	color: _system.color(text, inverse);
}

.c-nav__indicator {
	@include _system.typo(paragraph, md);

	display: flex;
	justify-content: center;
	align-items: center;
	width: _system.rem(24px);
	height: _system.rem(24px);
	margin-left: _system.space(4);

	color: _system.color(text, inverse);
	background-color: _system.color(background, dark);
	border-radius: 50%;

	@include _system.at("9") {
		@include _system.typo(paragraph, sm);

		width: _system.rem(20px);
		height: _system.rem(20px);
		margin-left: _system.space(2);

		color: _system.color(text, inverse);
		background-color: _system.color(background, primary);
	}
}

.c-nav__button {
	margin-top: _system.space(8);

	@include _system.at("9") {
		margin-top: 0;
		margin-left: _system.space(8);
	}
}

// =============================================================================
// States
// =============================================================================
.c-nav__link {
	&:hover,
	&:focus,
	&.is-active {
		background-size: 100% _system.rem(2px);

		@include _system.at("9") {
			background-size: 100% _system.rem(1px);
		}
	}
}

// =============================================================================
// Scopes
// =============================================================================
.s-inverted {
	.c-nav__link {
		color: _system.color(text, base);
	}
}
