@use "@littlemissrobot/sass-system" as _system;
@use "../settings" as settings;

// =============================================================================
// Block
// =============================================================================
.c-numbers-list {
	background-color: _system.color(background, base);
	border: settings.$borders_base;

	@include _system.at("9") {
		display: flex;
		flex-direction: row;
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-numbers-list__item {
	padding: _system.space(11) _system.space(6) _system.space(11)
		_system.space(4);

	@include _system.at("9") {
		padding: _system.space(12) _system.space(12) _system.space(12)
			_system.space(8);
	}

	& + .c-numbers-list__item {
		border-top: settings.$borders_base;

		@include _system.at("9") {
			border-top: none;
			border-left: settings.$borders_base;
		}
	}
}

.c-numbers-list__stat {
	@include _system.typo(numbers);

	color: _system.color(text, primary);
}

.c-numbers-list__description {
	@include _system.typo(paragraph, md);

	color: _system.color(text, light);
}
