@use "@littlemissrobot/sass-system" as _system;
@use "../settings/z" as z;

// =============================================================================
// Elements
// =============================================================================
.c-hero__inner {
	position: relative;

	display: flex;
	height: 50vh;
}

.c-hero__content {
	align-self: flex-end;
	padding-bottom: _system.space(8);
}

.c-hero__title {
	@include _system.typo(title, xl);
}

.c-hero__description {
	@include _system.typo(paragraph, lg, base);

	display: none;
	padding: _system.space(10);

	background: _system.color(background, base);
	color: _system.color(text, base);

	@include _system.at("7") {
		display: block;
	}

	@include _system.at("9") {
		padding: _system.space(15) _system.space(28);
	}
}

.c-hero__description-mobile {
	padding-top: _system.space(6);
	padding-bottom: _system.space(6);

	@include _system.at("7") {
		display: none;
	}
}

.c-hero__background {
	position: absolute;
	top: 0;
	left: 0;
	z-index: z.$background;

	object-fit: cover;
	object-position: center;
	width: 100%;
	height: 100%;
	overflow: hidden;

	background: _system.color(background, primary);
}

.c-hero__image {
	height: 100%;
}

.c-hero__overlay-decoration {
	@include _system.at("7") {
		position: absolute;
		right: 0;
		bottom: 0;
		z-index: z.$foreground;

		display: block;
		width: _system.rem(90px);
		height: _system.rem(90px);

		background-color: _system.color(background, primary);
		clip-path: polygon(100% 0, 100% 100%, 0 100%);
	}
}

.c-hero__overlay-color {
	position: absolute;
	top: 0;
	left: 0;
	z-index: z.$foreground;

	width: 100%;
	height: 100%;

	background-color: _system.color(background, dark-050);
	mix-blend-mode: multiply;
}

.c-hero__overlay-lines {
	position: absolute;
	top: 0;
	left: 0;
	z-index: z.$base;

	width: 100%;
	height: 100%;

	background-image: linear-gradient(
		135deg,
		white 0.26%,
		transparent 0.26%,
		transparent 50%,
		white 50%,
		white 50.26%,
		transparent 50.26%,
		transparent 100%
	);
	background-size: _system.rem(189px) _system.rem(189px);
	background-position: _system.rem(-30px) 0;

	@include _system.at("7") {
		background-image: linear-gradient(
			135deg,
			white 0.13%,
			transparent 0.13%,
			transparent 50%,
			white 50%,
			white 50.13%,
			transparent 50.13%,
			transparent 100%
		);
		background-size: _system.rem(381px) _system.rem(381px);
		background-position: _system.rem(-160px) 0;
	}
}

.c-hero__links {
	display: flex;
	align-items: center;
	flex-flow: row wrap;
	margin-top: _system.space(10);
	margin-right: _system.rem(-4px);
	margin-left: _system.rem(-4px);

	@include _system.at("7") {
		justify-content: center;
		margin-right: _system.rem(-10px);
		margin-left: _system.rem(-10px);
	}
}

.c-hero__link {
	flex-basis: auto;
	flex-grow: 0;
	flex-shrink: 1;
	margin: _system.rem(4px) _system.rem(4px);

	@include _system.at("7") {
		margin: _system.rem(5px) _system.rem(10px);
	}
}

// =============================================================================
// Variants
// =============================================================================
$description-adjust: 120px;

.c-hero--default {
	.c-hero__inner {
		@include _system.at("7") {
			margin-bottom: _system.rem($description-adjust);
		}
	}

	.c-hero__content {
		@include _system.at("7") {
			transform: translateY(_system.rem($description-adjust));
		}
	}

	.c-hero__description {
		@include _system.at("7") {
			margin-top: _system.space(10);
		}
	}
}

.c-hero--large {
	.c-hero__inner {
		height: 82vh;

		@include _system.at("7") {
			margin-bottom: _system.rem($description-adjust);
		}
	}

	.c-hero__title {
		@include _system.at("7") {
			text-align: center;
		}
	}

	.c-hero__content {
		@include _system.at("7") {
			transform: translateY(_system.rem($description-adjust));
		}
	}

	.c-hero__description {
		@include _system.at("7") {
			margin-top: _system.space(26);
		}
	}
}

.c-hero--centered {
	.c-hero__inner {
		@include _system.at("7") {
			margin-bottom: _system.rem($description-adjust);
		}
	}

	.c-hero__title {
		@include _system.at("7") {
			text-align: center;
		}
	}

	.c-hero__content {
		@include _system.at("7") {
			padding-bottom: _system.rem(48px);

			transform: translateY(_system.rem($description-adjust));
		}
	}

	.c-hero__description {
		position: relative;

		&::before {
			content: "";

			position: absolute;
			top: 0;
			left: 0;

			width: 100%;
			height: _system.rem(23px);

			background-image: linear-gradient(
				135deg,
				_system.color(background, secondary) 50%,
				_system.color(background, primary) 50%,
				_system.color(background, primary) 100%
			);
		}

		@include _system.at("7") {
			margin-top: _system.space(10);
		}
	}

	.c-hero__description-mobile {
		position: relative;

		padding-top: _system.space(8.5);

		&::before {
			content: "";

			position: absolute;
			top: 0;
			left: 0;

			width: 100%;
			height: _system.rem(10px);

			background-image: linear-gradient(
				135deg,
				_system.color(background, secondary) 50%,
				_system.color(background, primary) 50%,
				_system.color(background, primary) 100%
			);
		}
	}
}

.c-hero--full {
	align-items: center;

	@include _system.at("7") {
		text-align: center;
	}

	.c-hero__inner {
		height: 82vh;
	}

	.c-hero__content {
		align-self: center;
		padding-bottom: 0;
	}

	.c-hero__description {
		display: block;
		margin-top: _system.space(10);
		padding: 0;

		background: none;
	}
}
