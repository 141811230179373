@use "@littlemissrobot/sass-system" as _system;
@use "../settings" as settings;

// =============================================================================
// Block
// =============================================================================
.c-flyout {
	@include _system.at("9") {
		padding: _system.space(2) _system.space(4);

		background-color: _system.color(background, base);
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-flyout__item {
	& + .c-flyout__item {
		@include _system.at("9") {
			border-top: settings.$borders_base;
		}
	}
}

.c-flyout__link {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: _system.space(2) 0;

	@include _system.to("9") {
		padding: _system.space(4) 0;
	}
}

.c-flyout__title {
	@include _system.typo(paragraph, md);

	display: flex;

	color: _system.color(text, inverse);
	background-image: linear-gradient(currentColor, currentColor);
	background-position: 0% 100%;
	background-repeat: no-repeat;
	background-size: 0% _system.rem(1px);

	transition: background-size settings.$animation_base,
		color settings.$animation_base;

	@include _system.at("9") {
		color: _system.color(text, base);
	}
}

.c-flyout__text {
	@include _system.typo(paragraph, sm);

	color: _system.color(text, inverse);
	opacity: 0.7;

	@include _system.at("9") {
		color: _system.color(text, light);
		opacity: 1;
	}
}

// =============================================================================
// States
// =============================================================================
.c-flyout__link {
	&:hover,
	&:focus,
	&.is-active {
		.c-flyout__title {
			background-size: 100% _system.rem(2px);

			@include _system.at("9") {
				background-size: 100% _system.rem(1px);
			}
		}
	}
}
