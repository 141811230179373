@use "@littlemissrobot/sass-system" as _system;
@use "../settings" as settings;

// =============================================================================
// Block
// =============================================================================
.c-mobile-menu {
	position: fixed;
	top: 0;
	left: 0;
	z-index: settings.$z_foreground;

	width: 100%;
	height: 100%;
	overflow: hidden;
	padding-top: _system.space(32);

	background-color: _system.color(background, primary);
	opacity: 0;

	transition: opacity 0.15s ease-in-out, visibility 0s ease-in-out 0.1s;

	pointer-events: none;
	visibility: hidden;
}

// =============================================================================
// Elements
// =============================================================================
.c-mobile-menu__container {
	flex: 1;
	overflow-y: auto;
}

// =============================================================================
// States
// =============================================================================
.c-mobile-menu {
	&.is-open {
		height: 100vh;

		opacity: 1;

		transition: opacity 0.1s ease-in-out 0.01s, visibility 0s ease-in-out;

		pointer-events: auto;
		visibility: visible;
	}
}
