@use "@littlemissrobot/sass-system" as _system;

$triangle: _system.rem(90px);

// =============================================================================
// Block
// =============================================================================
.c-picture {
	position: relative;

	object-fit: cover;
	object-position: center;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

// =============================================================================
// Element
// =============================================================================
.c-picture__image {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.c-picture__decoration {
	position: absolute;
	top: 0;
	left: 0;

	display: block;
	width: _system.rem(90px);
	height: _system.rem(90px);

	background-color: _system.color(background, primary);
	clip-path: polygon(0 0, 100% 0, 0 100%);
}

// =============================================================================
// Modifiers
// =============================================================================
.c-picture__decoration--secondary {
	background-color: _system.color(background, secondary);
}

.c-picture__decoration--dark {
	background-color: _system.color(background, dark);
}

.c-picture__decoration--bottom {
	top: unset;
	bottom: 0;

	clip-path: polygon(0 0, 100% 100%, 0 100%);
}

.c-picture__decoration--right {
	right: 0;
	left: unset;

	clip-path: polygon(0 0, 100% 0, 100% 100%);
}

.c-picture__decoration--bottom {
	&.c-picture__decoration--right {
		top: unset;
		right: 0;
		bottom: 0;
		left: unset;

		clip-path: polygon(100% 0, 100% 100%, 0 100%);
	}
}
