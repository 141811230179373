@use "@littlemissrobot/sass-system" as _system;

// =============================================================================
// Modifiers
// =============================================================================
.c-site-logo__image--inverse {
	display: none;
}

// =============================================================================
// Scopes
// =============================================================================
.s-inverted {
	.c-site-logo__image {
		display: none;
	}

	.c-site-logo__image--inverse {
		display: block;
	}
}
