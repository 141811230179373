// 1. Allow labels to use `margin` for spacing.
label {
	display: inline-block; // 1
}

// 1. Remove the default `border-radius` that macOS Chrome adds.
button {
	border-radius: 0; // 1
}

// 1. Remove the inheritance of word-wrap in Safari.
select {
	word-wrap: normal; // 1
}

// 1. Add `pointer` cursor to non-disabled button elements.
button,
[type="button"],
[type="reset"],
[type="submit"] {
	&:not(:disabled) {
		cursor: pointer; // 1
	}
}

/**
 * 1. Textareas should really only resize vertically so they don't break their
 *    (horizontal) containers.
 */
textarea {
	resize: vertical; // 1
}

/**
 * 1. Reset default `min-width: min-content;` on fieldsets in order to behave it
 *    more like a standard block element.
 * 2. Reset the default outline behavior
 */
fieldset {
	min-width: 0; // 1
	padding: 0; // 2

	border: 0; // 2
}

/**
 * 1. By using `float: left`, the legend will behave like a block element.
 * 2. Reset default margin.
 * 3. Fix wrapping bug.
 */

legend {
	float: left; // 1
	width: 100%;
	margin: 0; // 2

	+ * {
		clear: left; // 3
	}
}
