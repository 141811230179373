@use "@littlemissrobot/sass-system" as _system;

/**
 * Create a containing element that places a max-width on the content and
 * centers it along the viewport. Can also add padding on the left and right
 * side to separate the content from the viewport.
 */
@use "@littlemissrobot/sass-container" as _container with (
	$settings: (
		max-width: 1440px,
		spacing: (
			default: 4,
			"7": 6,
			"12": 8,
		)
	),
	$container: (
		default: true,
		to: ("9",),
		at: ("9",),
	),
	$container-width: (),
	$container-space: (),
	$container-space-l: (
		default: true,
		to: ("9",),
	),
	$container-space-r: (
		default: true,
	),
	$variants: (
		"5": (
			max-width: 541px,
			container-width: (
				default: true,
			),
		),
		"6": (
			max-width: 674px,
			container-width: (
				default: true,
			),
		),
		"8": (
			max-width: 876px,
			container-width: (
				default: true,
			),
		),
		"10": (
			max-width: 1065px,
			container-width: (
				default: true,
			),
		),
		"11": (
			max-width: 1144px,
			container-width: (
				default: true,
			),
		),
	),
);

// prettier-ignore
.u-full-bleed {
	@include _system.generate-default((
		default: false,
		at: (),
		to: (),
	)) {
		width: 100vw;
		margin-left: calc(50% - 50vw);
	}
}

[class*="u-container"].u-container-mx-0 {
	margin-right: 0;
	margin-left: 0;
}

[class*="u-container"].u-container-px-0 {
	padding-right: 0;
	padding-left: 0;
}
