@use "@littlemissrobot/sass-system" as _system;

.u-ty--30\@9 {
	@include _system.at("9") {
		transform: translateY(_system.space(-30));
	}
}

.u-ty--10\@12 {
	@include _system.at("12") {
		transform: translateY(_system.space(-10));
	}
}
