@use "@littlemissrobot/sass-system" as _system;
@use "../settings" as settings;

// =============================================================================
// Block
// =============================================================================
.c-link {
	@include _system.typo(paragraph, lg, base);

	color: _system.color(interactive, primary, base);

	transition: color settings.$animation_base;
}

// =============================================================================
// Elements
// =============================================================================
.c-link__icon {
	margin-left: _system.space(5);

	transition: transform settings.$animation_base;
}

// =============================================================================
// States
// =============================================================================
.c-link {
	&:hover,
	&:focus {
		color: _system.color(interactive, primary, hover);

		.c-link__icon {
			transform: translateX(_system.space(2));
		}
	}

	&.is-disabled {
		color: _system.color(text, light);

		cursor: not-allowed;

		&:hover,
		&:focus {
			.c-link__icon {
				transform: none;
			}
		}
	}
}
