@use "@littlemissrobot/sass-system" as _system;

// prettier-ignore
.u-fill-width {
	@include _system.generate-default((
		default: false,
		at: (),
		to: ()
	)) {
		width: 100%;
	}
}

// prettier-ignore
.u-fill-height {
	@include _system.generate-default((
		default: true,
		at: (),
		to: ()
	)) {
		height: 100%;
	}
}

// prettier-ignore
.u-fill-space {
	@include _system.generate-default((
		default: false,
		at: (),
		to: ()
	)) {
		width: 100%;
		height: 100%;
	}
}
