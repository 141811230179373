@use "@littlemissrobot/sass-system" as _system;

// =============================================================================
// Elements
// =============================================================================
.c-info-block__image {
	@include _system.to("9") {
		position: relative;

		padding-top: 57%;

		.c-picture__image {
			position: absolute;
			top: 0;
			left: 0;

			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}
}
