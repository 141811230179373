@use "@littlemissrobot/sass-system" as _system;

// 1. Remove defaults on lists with class, indicates custom styling.
ul[class]:not(.c-list),
ol[class]:not(.c-list) {
	list-style: none; // 1
}

// 1. Remove trailing margins from nested lists.
li > {
	ol,
	ul {
		margin-bottom: 0; // 1
	}
}
