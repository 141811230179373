@use "@littlemissrobot/sass-system" as _system;
@use "../settings" as settings;

// =============================================================================
// Elements
// =============================================================================
.c-article__title {
	@include _system.typo(title, md);

	color: _system.color(text, base);
}

.c-article__description {
	@include _system.typo(paragraph, lg);

	color: _system.color(text, light);
}

.c-article__case-label {
	@include _system.typo(title, sm);

	display: block;

	color: _system.color(text, base);
}

.c-article__image {
	height: _system.rem(350px);

	@include _system.at("9") {
		height: _system.rem(450px);
	}
}
