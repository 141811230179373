/**
 * 1. Removes whitespace under the <img> elements when they are dropped into a
 *    page without any styling. Safer alternative than display: block;
 */

img,
svg {
	vertical-align: middle; // 1
}

/**
 * 1. Fluid images for responsiveness
 * 2. Differentiate alt text when image is not shown from surrounding copy.
 * 3. If a `width` and/or `height` attribute has been defined, don't make the
 *    image fluid.
 */

img {
	max-width: 100%; // 1

	font-style: italic; // 2

	&[width],
	&[height] {
		max-width: none; // 3
	}
}
