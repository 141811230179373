@use "@littlemissrobot/sass-system" as _system;

// =============================================================================
// Block
// =============================================================================
.c-logos {
	gap: _system.space(5);

	@include _system.at("9") {
		gap: _system.space(8);
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-logos__img {
	display: inline-flex;
	align-items: center;
	width: auto;
	height: 100%;
	max-height: _system.rem(60px);
}
