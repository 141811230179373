@use "@littlemissrobot/sass-system" as _system;

// =============================================================================
// Block
// =============================================================================
.c-cta {
	padding-top: _system.space(12);

	background: _system.color(background, primary);

	@include _system.at("7") {
		padding: _system.space(20) 0;
	}

	@include _system.at("9") {
		padding: _system.space(28) 0;
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-cta__inner {
	padding-bottom: _system.rem(173px);

	background-image: url("/assets/images/circuitry-vertical.svg");
	background-repeat: no-repeat;
	background-position: bottom right _system.rem(16px);
	background-size: 50% auto;

	@include _system.at("4") {
		background-size: auto 60%;
	}

	@include _system.at("7") {
		display: flex;
		align-items: center;
		flex-flow: row nowrap;
		padding-bottom: 0;

		background: none;
	}
}

.c-cta__image {
	display: none;

	@include _system.at("7") {
		display: block;
		flex-basis: 50%;
		max-width: 50%;
		margin-right: _system.space(15);
	}

	@include _system.at("9") {
		flex-basis: 26%;
		max-width: 26%;
		margin-right: _system.space(30);
	}
}
