@use "@littlemissrobot/sass-system" as _system;

// =============================================================================
// Variants
// =============================================================================
.c-images--count-1 {
	.c-images__picture {
		position: relative;

		padding-top: 57%;
	}

	.c-picture__image {
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;
	}
}

.c-images--count-2 {
	.c-images__picture {
		padding-top: 120%;
	}
}
