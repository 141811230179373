@use "@littlemissrobot/sass-system" as _system;
@use "../settings" as settings;

// =============================================================================
// Block
// =============================================================================
.c-header {
	position: fixed;
	top: 0;
	left: 50%;
	z-index: settings.$z_foreground + 1;

	width: 100%;
	padding-top: _system.space(4);
	padding-bottom: _system.space(4);

	transform: translateX(-50%);
	transition: background-color settings.$animation-base;

	@include _system.at("9") {
		padding-top: _system.space(8);
		padding-bottom: _system.space(8);
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-header__container {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.c-header__logo {
	z-index: settings.$z_foreground + 1;
}

.c-header__hamburger {
	position: absolute;
	top: 0;
	right: 0;
	z-index: settings.$z_foreground + 1;
}

// =============================================================================
// Scopes
// =============================================================================
.s-inverted {
	&.c-header {
		background-color: _system.color(background, base);
	}
}
