@use "@littlemissrobot/sass-system" as _system;
@use "../settings" as settings;

// =============================================================================
// Block
// =============================================================================
.c-info {
	background-color: _system.color(background, default);
	color: _system.color(text, base);
}

// =============================================================================
// Elements
// =============================================================================
.c-info__content {
	padding: 0 _system.space(5) _system.space(5) _system.space(5);
}

.c-info__title {
	@include _system.typo(title, sm);

	color: _system.color(text, base);
}

.c-info__description {
	@include _system.typo(paragraph, lg);

	color: _system.color(text, light);

	@include _system.at("7") {
		margin-top: _system.space(2);
	}
}

.c-info__tags {
	margin-top: _system.space(3);

	/*
	 * Tags have spacing top and bottom on itselves. To avoid too much spacing
	 * between the tags and the button, we adjust the spacing when the tags are
	 * followed by a button
	 */
	& + .c-info__button {
		margin-top: _system.space(3);
	}
}

.c-info__button {
	margin-top: _system.space(5);

	@include _system.to("7") {
		width: 100%;
	}
}

.c-info__icon {
	margin-bottom: _system.space(4);
}

// =============================================================================
// Modifiers
// =============================================================================
.c-info--link {
	transition: background-color settings.$animation_base;
}

.c-info--centered {
	display: flex;
	flex-direction: column;
	align-items: center;

	text-align: center;
}

.c-info--large {
	@include _system.typo(title, md);
}

// =============================================================================
// States
// =============================================================================
.c-info--link {
	&:hover {
		background-color: _system.color(interactive, default, hover);
	}
}
