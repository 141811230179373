@use "@littlemissrobot/sass-system" as _system;
@use "../settings" as settings;

$overlay-skew: 45deg;

// =============================================================================
// Block
// =============================================================================
.c-testimonial {
	position: relative;

	display: flex;
	flex-flow: column nowrap;

	background-color: _system.color(background, dark);
	color: _system.color(text, inverse);

	@include _system.at("9") {
		padding: _system.space(37) 0;
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-testimonial__text {
	z-index: settings.$z_foreground;

	order: -1;
	padding-top: _system.space(8);
	padding-bottom: _system.space(8);

	@include _system.at("7") {
		padding-top: _system.space(16);
		padding-bottom: _system.space(16);
	}

	@include _system.at("9") {
		order: 0;
		padding-top: 0;
		padding-bottom: 0;
	}
}

.c-testimonial__quote {
	@include _system.typo(testimonial);
}

.c-testimonial__source {
	margin-top: _system.space(9);
}

.c-testimonial__image {
	@include _system.to("9") {
		position: relative;

		padding-top: 75%;
	}

	@include _system.at("9") {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;

		width: 50%;
	}
}

.c-testimonial__image-inner {
	@include _system.to("9") {
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;
	}

	@include _system.at("9") {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
}

.c-testimonial__picture {
	.c-picture__decoration {
		@include _system.to("9") {
			display: none;
		}
	}
}

.c-testimonial__overlay {
	position: absolute;
	top: 0;
	left: 45%;

	width: 200%;
	height: 100%;
	overflow: hidden;

	background: _system.color(background, dark);
	border-left: settings.$borders_testimonial_border;

	transform: skew(-$overlay-skew);
	transform-origin: left top;

	@include _system.to("9") {
		display: none;
	}
}

.c-testimonial__decoration {
	position: absolute;
	bottom: _system.rem(40px);
	left: -1%;

	max-width: 30%;
	height: 40%;
	mix-blend-mode: soft-light;

	transform: skew($overlay-skew);
}
