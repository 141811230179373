@use "@littlemissrobot/sass-system" as _system;
@use "../settings" as settings;

// =============================================================================
// Block
// =============================================================================
.c-site-footer {
	background-color: _system.color(background, dark);
	color: _system.color(text, inverse);
}

// =============================================================================
// Elements
// =============================================================================
.c-site-footer__top {
	padding-top: _system.space(12);
	padding-bottom: _system.space(8);

	@include _system.at("7") {
		padding-top: _system.space(16);
		padding-bottom: _system.space(10);
	}
}

.c-site-footer__bottom {
	padding-top: _system.space(6);
	padding-bottom: _system.space(8);

	border-top: 1px solid hsl(0, 0%, 100%, 30%);

	@include _system.typo(paragraph, sm);

	@include _system.at("7") {
		padding-top: _system.space(10);
		padding-bottom: _system.space(10);
	}
}

.c-site-footer__link {
	color: _system.color(text, inverse);
	background: linear-gradient(currentColor 0 0) 0 100% / 0 _system.rem(1px)
		no-repeat;

	transition: settings.$animation_base,
		background-position 0s settings.$animation_base;
}

.c-site-footer__title {
	@include _system.typo(paragraph, lg, bold);
}

.c-site-footer__location {
	&:not(:last-child) {
		margin-bottom: _system.space(6);
	}
}

.c-site-footer__link-item {
	&:not(:last-child) {
		margin-bottom: _system.space(2);
	}
}

.c-site-footer__legal {
	flex-flow: column nowrap;

	@include _system.at("7") {
		flex-flow: row nowrap;
	}
}

.c-site-footer__legal-item {
	margin-bottom: _system.space(2);

	@include _system.at("7") {
		margin-right: _system.space(8);
	}
}

.c-site-footer__locations-col {
	@include _system.to("7") {
		&::after {
			content: "";

			display: block;
			margin-right: _system.space(-4);
			margin-left: _system.space(-4);
			padding-top: _system.space(8);

			border-bottom: 1px solid hsl(0, 0%, 100%, 30%);
		}
	}
}

// =============================================================================
// Modifiers
// =============================================================================
.c-site-footer__link--underline {
	background: linear-gradient(currentColor 0 0) 0 100% / 100% _system.rem(1px)
		no-repeat;
}

// =============================================================================
// States
// =============================================================================
.c-site-footer__link {
	&:hover,
	&:focus {
		background: linear-gradient(currentColor 0 0) 100% 100% / 100%
			_system.rem(1px) no-repeat;
	}
}
