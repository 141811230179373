@use "@littlemissrobot/sass-system" as _system;
@use "../settings" as settings;

// =============================================================================
// Block
// =============================================================================
.c-numbers {
	padding-top: _system.space(35);
}

// =============================================================================
// Elements
// =============================================================================
.c-numbers__list {
	padding-bottom: _system.rem(240px);

	background: url("/assets/images/circuitry-vertical-blue.svg"),
		linear-gradient(
			_system.color(background, base) calc(100% - #{_system.rem(320px)}),
			_system.color(background, dark) calc(100% - #{_system.rem(320px)})
		);
	background-position: center bottom _system.rem(40px), center bottom;
	background-size: _system.rem(180px) auto, 100% 100%;
	background-repeat: no-repeat;

	@include _system.at("9") {
		padding-bottom: _system.rem(200px);

		background: url("/assets/images/background-circuitry.svg"),
			linear-gradient(
				_system.color(background, base) _system.rem(96px),
				_system.color(background, dark) _system.rem(96px)
			);
		background-position: center top _system.rem(96px), center bottom;
		background-size: auto calc(100% - #{_system.rem(96px)}), 100% 100%;
		background-repeat: no-repeat;
	}
}
