@use "@littlemissrobot/sass-system" as _system;

// =============================================================================
// Block
// =============================================================================
.c-tags {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

// =============================================================================
// Elements
// =============================================================================
.c-tags__item {
	@include _system.typo(tag);

	margin-top: _system.space(2);
	margin-right: _system.space(4);
	margin-bottom: _system.space(2);

	color: _system.color(text, highlight);
}

// =============================================================================
// Modifiers
// =============================================================================
.c-tags--centered {
	justify-content: center;

	.c-tags__item {
		margin-right: _system.space(2);
		margin-left: _system.space(2);
	}
}
