@use "@littlemissrobot/sass-system" as _system;

h1 {
	// @include _system.typo(h1);

	// max-width: 15ch;
}

h2,
h3,
h4,
h5,
h6 {
	// max-width: 25ch;
}

h2 {
	// @include _system.typo(h2);
}

h3 {
	// @include _system.typo(h3);
}

h4 {
	// @include _system.typo(h4);
}

h5 {
	// @include _system.typo(h5);
}

h6 {
	// @include _system.typo(h6);
}

p {
	// @include _system.typo(p);

	// max-width: 60ch;
}

// 1. Remove default styles from address.
address {
	font-style: normal; // 1
	line-height: inherit; // 1
}
