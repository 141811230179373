@use "@littlemissrobot/sass-system" as _system;

// prettier-ignore
.s-wysiwyg {
	--list-marker-color: #{_system.color(background, secondary)};

	color: _system.color(text, light);

	strong {
		@include _system.typo(paragraph, lg, bold);
	}

	a {
		text-decoration: none;

		color: _system.color(text, primary);

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}

	ol,
	ul {
		li:not(:last-child) {
			margin-bottom: _system.space(3);
		}
	}

	ul {
		padding-left: _system.space(5);

		li {
			padding-left: _system.space(1);

			list-style-type: square;

			&::marker {
				color: var(--list-marker-color);
			}
		}
	}
}

.s-wysiwyg__text {
	* {
		margin-bottom: _system.space(8);
	}

	.s-wysiwyg__subheading {
		margin-bottom: _system.space(3);
	}

	*:last-child {
		margin-bottom: 0;
	}
}

// =============================================================================
// Modifiers
// =============================================================================
.s-wysiwyg--primary {
	--list-marker-color: #{_system.color(background, primary)};
}

.s-wysiwyg--dark {
	--list-marker-color: #{_system.color(background, dark)};
}
