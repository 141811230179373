// stylelint-disable selector-max-universal

@use "@littlemissrobot/sass-system" as _system;

// prettier-ignore
.u-stack {
	@include _system.generate-value((
		value: (2, 4, 6, 8, 9, 16),
		at: (
			"9": (3, 6)
		),
		to: (),
	)) using ($item) {
		& > * + * {
			margin-top: _system.space($item);
		}
	}
}

// prettier-ignore
.u-inline {
	@include _system.generate-value((
		value: (),
		at: (),
		to: (),
	)) using ($item) {
		& > * + * {
			margin-left: _system.space($item);
		}
	}
}

// prettier-ignore
.u-inline-wrap {
	@include _system.generate-value((
		value: (),
		at: (),
		to: (),
	)) using ($item) {
		$space: _system.space($item) * 0.5;

		margin-right: $space * -1;
		margin-left: $space * -1;

		& > * {
			margin-right: $space;
			margin-left: $space;
		}
	}
}

// prettier-ignore
$margin: (
	value:(),
	at: (),
	to: (),
);

// prettier-ignore
$margin-x: (
	value:(),
	at: (),
	to: (),
);

// prettier-ignore
$margin-y: (
	value:(),
	at: (),
	to: (),
);

// prettier-ignore
$margin-top: (
	value:(4, 8, 15, 16),
	at: (
		"9": (15,),
	),
	to: (
		"7": (4,),
		"9": (4,)
	),
);

// prettier-ignore
$margin-bottom: (
	value:(2, 3, 4, 5, 10, 20, 37),
	at: (),
	to: (),
);

// prettier-ignore
$margin-left: (
	value:(0, 4),
	at: (),
	to: (),
);

// prettier-ignore
$margin-right: (
	value:( 3, ),
	at: (),
	to: (),
);

// prettier-ignore
$padding: (
	value:(),
	at: (),
	to: (),
);

// prettier-ignore
$padding-x: (
	value:(),
	at: (),
	to: (),
);

// prettier-ignore
$padding-y: (
	value:(4, 8, 16, 37),
	at: (
		"9": (37)
	),
	to: (),
);

// prettier-ignore
$padding-top: (
	value:(4, 8, 10),
	at: (
		"9": (25,),
	),
	to: (),
);

// prettier-ignore
$padding-bottom: (
	value:(4, 16, 30),
	at: (
		"9": (38, 50,),
	),
	to: (),
);

// prettier-ignore
$padding-left: (
	value:(),
	at: (),
	to: (),
);

// prettier-ignore
$padding-right: (
	value:(),
	at: (),
	to: (),
);

.u-ml--5\@7 {
	@include _system.at("7") {
		margin-left: _system.space(-5);
	}
}
