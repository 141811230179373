@use "@littlemissrobot/sass-system" as _system;

$_hamburger-height: _system.rem(20px);
$_hamburger-width: _system.space(7);
$_bar-height: _system.rem(3px);
$_middle: _system.rem(7px);

// =============================================================================
// Block
// =============================================================================
.c-hamburger {
	position: relative;

	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: flex-start;
	width: _system.rem(140px);
	height: _system.rem(140px);

	background-color: _system.color(background, primary);
	clip-path: polygon(0 0, 100% 0, 100% 100%);
}

// =============================================================================
// Elements
// =============================================================================
.c-hamburger__inner {
	position: relative;
	bottom: auto;
	left: auto;

	display: block;
	width: $_hamburger-width;
	height: $_hamburger-height;
	margin-top: _system.space(10);
}

.c-hamburger__top-bar,
.c-hamburger__middle-bar,
.c-hamburger__bottom-bar {
	position: absolute;
	right: 0;

	display: block;
	width: 100%;
	height: $_bar-height;

	background-color: _system.color(interactive, default, base);

	transition: transform 0.3s ease-in-out;
}

.c-hamburger__top-bar {
	top: 0;

	transform-origin: left;
}

.c-hamburger__middle-bar {
	top: calc(50% - (#{$_bar-height} * 0.5));
	right: 0;

	transform: scaleX(0.66) translateX(25%);
}

.c-hamburger__bottom-bar {
	bottom: 0;

	width: 100%;

	transform-origin: right;
	transform: scaleX(0.33);
}

// =============================================================================
// States
// =============================================================================
.c-hamburger {
	&.is-cross {
		.c-hamburger__top-bar {
			transform: translateX(_system.rem(5px)) rotate(45deg) scaleX(0.45);
		}

		.c-hamburger__middle-bar {
			width: 100%;

			transform-origin: center;
			transform: rotate(-45deg) scaleX(0.9);
			transition: transform 0.3s ease-in-out;
		}

		.c-hamburger__bottom-bar {
			transform: translateX(_system.rem(-5px)) rotate(45deg) scaleX(0.45);
		}
	}
}
