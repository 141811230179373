@use "@littlemissrobot/sass-system" as _system;

$typography: (
	value: (
		(title, xs),
		(title, sm),
		(title, md),
		(title, lg),
		(title, xl),
		(paragraph, lg, base),
		(paragraph, lg, bold),
		(tag),
	),
	at: (),
	to: (),
);

// prettier-ignore
.u-text-upper {
	@include _system.generate-default((
		default: true,
		at: (),
		to: ()
	)) {
		text-transform: uppercase;
	}
}

// prettier-ignore
.u-text-lower {
	@include _system.generate-default((
		default: false,
		at: (),
		to: ()
	)) {
		text-transform: lowercase;
	}
}
