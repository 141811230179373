@use "@littlemissrobot/sass-system" as _system;

$_baseline: 16px;
$sizes: (
	value: (
		6,
		8,
	),
	at: (),
	to: (),
);

// =============================================================================
// Block
// =============================================================================
.c-icon {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	vertical-align: middle;
	width: $_baseline;
	height: $_baseline;

	fill: currentColor;

	// Style the included SVG icon by directly selecting the SVG.
	& > svg {
		vertical-align: middle;
		width: inherit;
		height: inherit;

		fill: currentColor;
	}
}

// =============================================================================
// Modifiers
// =============================================================================
// Renders classes like c-icon--size-1
.c-icon--size {
	@include _system.generate-value($sizes) using ($item) {
		width: _system.space($item);
		height: _system.space($item);
	}
}
