@use "@littlemissrobot/sass-system" as _system;

// =============================================================================
// Block
// =============================================================================
.c-quotes {
	position: relative;

	padding: _system.space(15) 0;

	background-color: _system.color(background, dark);
	color: _system.color(text, inverse);
}

// =============================================================================
// Elements
// =============================================================================
.c-quotes__item {
	position: relative;

	width: 100%;
	padding: _system.space(8);

	@include _system.at("9") {
		width: calc(100% / 3);
	}

	& + .c-quotes__item {
		margin-top: _system.space(4);

		@include _system.at("9") {
			margin-top: 0;
			margin-left: _system.space(8);
		}
	}
}

.c-quotes__attribute {
	@include _system.typo(title, xl);

	color: _system.color(text, highlight);
}

.c-quotes__text {
	@include _system.typo(paragraph, lg, bold);

	color: _system.color(text, inverse);
}

.c-quotes__author {
	@include _system.typo(tag);

	margin-top: _system.space(4);

	color: _system.color(text, primary);
}

.c-quotes__decoration {
	position: absolute;
	bottom: _system.rem(10px);
	left: -1%;

	max-width: 50%;
	height: auto;
	mix-blend-mode: soft-light;

	@include _system.at("9") {
		bottom: _system.rem(40px);

		max-width: 20%;
	}
}

.c-quotes__list {
	display: flex;
	flex-direction: column;
	justify-content: center;

	@include _system.at("9") {
		flex-direction: row;
	}
}
