@use "@littlemissrobot/sass-system" as _system;

// =============================================================================
// Block
// =============================================================================
.c-quote {
	padding: _system.space(12) _system.space(4);

	background-color: _system.color(background, light);

	@include _system.at("7") {
		padding: _system.space(12) _system.space(16);
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-quote__text {
	@include _system.typo(quote);

	text-align: center;

	color: _system.color(text, primary);
}

.c-quote__author {
	@include _system.typo(title, xs);

	text-align: center;

	color: _system.color(text, base);
}
