@use "@littlemissrobot/sass-system" as _system;

// =============================================================================
// Block
// =============================================================================
.c-list {
	--list-marker-color: _system.color(background, secondary);

	list-style-position: inside;
}

// =============================================================================
// Elements
// =============================================================================
.c-list__item {
	@include _system.typo(paragraph, lg, base);

	color: _system.color(text, light);
}

// =============================================================================
// States
// =============================================================================
/* stylelint-disable selector-no-qualifying-type */
ul.c-list {
	.c-list__item {
		list-style-type: square;

		&::marker {
			color: var(--list-marker-color);
		}
	}
}

ol.c-list {
	.c-list__item {
		position: relative;

		padding-left: _system.space(6);

		counter-increment: list-counter;

		list-style-type: none;

		&::before {
			@include _system.typo(paragraph, lg, bold);

			content: counter(list-counter) ". ";

			position: absolute;
			top: 0;
			left: 0;

			color: var(--list-marker-color);
		}
	}
}
/* stylelint-enable selector-no-qualifying-type */
