@use "@littlemissrobot/sass-system" as _system;
@use "../settings" as settings;

// =============================================================================
// Block
// =============================================================================
.c-card {
	display: block;
	padding: _system.space(4);

	transition: background settings.$animation_base;

	@include _system.at("7") {
		display: flex;
		align-items: center;
	}

	@include _system.at("9") {
		display: block;

		&:nth-child(3n + 2) {
			transform: translateY(_system.rem(50px));
		}
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-card__image-container {
	position: relative;

	width: 100%;

	&::after {
		content: "";

		display: block;
		padding-bottom: 100%;
	}
}

.c-card__info {
	@include _system.between("7", "9") {
		padding-left: _system.space(4);
	}
}

.c-picture.c-card__image,
.c-card__image .c-picture__image {
	position: absolute;

	object-fit: cover;
	width: 100%;
	height: 100%;
}

.c-card__overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	width: 100%;
	height: 100%;
	padding: _system.space(4) _system.space(23) _system.space(4)
		_system.space(5);

	opacity: 0;

	transition: opacity settings.$animation_base;
}

.c-card__icon-container {
	position: absolute;
	top: 0;
	right: 0;

	display: flex;
	width: _system.rem(90px);
	height: _system.rem(90px);

	background-color: _system.color(interactive, secondary, base);
	clip-path: polygon(100% 0, 0 0, 100% 100%);
}

.c-card__icon {
	position: absolute;

	width: 50%;
	height: auto;
	margin-top: _system.rem(18px);
	margin-left: _system.rem(36px);

	transition: transform settings.$animation_base;
	transform: translateX(-20%);
}

.c-card__tags {
	@include _system.at("9") {
		max-width: 90%;
	}
}

// =============================================================================
// States
// =============================================================================
.c-card {
	&:hover,
	&:focus {
		background: _system.color(interactive, default, base);

		@include _system.at("9") {
			.c-card__overlay {
				opacity: 1;
			}

			.c-card__icon {
				transform: translateX(0);
			}
		}
	}
}
