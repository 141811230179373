/**
 * CSS RESET based on:
 * - http://meyerweb.com/eric/tools/css/reset/
 * - https://github.com/hankchizljaw/modern-css-reset/blob/master/src/reset.css
 */

// Box sizing rules
*,
*::before,
*::after {
	box-sizing: border-box;
}

// Set core root defaults
html:focus-within {
	scroll-behavior: smooth;
}

// Set core body defaults
body {
	line-height: 1;
	text-rendering: optimizeSpeed;
}

// Remove defaults
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	vertical-align: baseline;
	margin: 0;
	padding: 0;

	font: inherit;
	font-size: 100%;

	border: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

blockquote,
q {
	quotes: none;

	&::before,
	&::after {
		content: "";
		content: none;
	}
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

// Make images easier to work with
img,
picture {
	display: block;
	max-width: 100%;
}

// Inherit fonts for inputs and buttons
input,
button,
textarea,
select {
	font: inherit;
}

// Remove all animations and transitions for people that prefer not to see them
@media (prefers-reduced-motion: reduce) {
	html:focus-within {
		scroll-behavior: auto;
	}

	*,
	*::before,
	*::after {
		// stylelint-disable declaration-no-important
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		transition-duration: 0.01ms !important;
		scroll-behavior: auto !important;
		// stylelint-enable declaration-no-important
	}
}
