@use "@littlemissrobot/sass-system" as _system;

// =============================================================================
// Block
// =============================================================================
.c-cases__cards {
	@include _system.at("9") {
		margin-right: _system.space(-4);
		margin-left: _system.space(-4);
	}
}
