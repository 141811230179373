@use "@littlemissrobot/sass-system" as _system;
@use "../settings/" as settings;

.u-background__circuity {
	position: absolute;
	right: 0;
	bottom: _system.space(5);

	width: 70%;
	height: auto;

	transform: scaleX(-1);

	@include _system.at("9") {
		bottom: _system.space(20);

		width: _system.space(100);
	}
}

.u-background--slanted {
	position: absolute;
	top: 0;
	right: 0;
	z-index: settings.$z_background;

	display: block;
	width: 100%;
	height: 100%;

	background-image: linear-gradient(
		315deg,
		_system.color(background, primary) 30%,
		_system.color(background, base) calc(30% + 2px)
	);

	@include _system.at("9") {
		width: 60%;

		background-image: linear-gradient(
			315deg,
			_system.color(background, primary) 35%,
			_system.color(background, base) calc(35% + 2px)
		);
	}

	@include _system.at("12") {
		width: 45%;
	}
}

.u-background__circuitry--primary {
	position: relative;

	&::after {
		content: "";

		position: absolute;
		bottom: _system.rem(-100px);
		left: 0;
		z-index: settings.$z_background;

		display: block;
		width: 30%;
		max-width: _system.rem(500px);
		height: 100%;

		background-image: url("/assets/images/circuitry-blue.svg");
		background-repeat: no-repeat;
		background-position: bottom;

		@include _system.to("9") {
			display: none;
		}
	}
}

.u-background__circuitry--secondary {
	position: relative;

	&::after {
		content: "";

		position: absolute;
		top: _system.rem(-100px);
		right: 0;
		z-index: settings.$z_background;

		display: block;
		width: 30%;
		max-width: _system.rem(500px);
		height: 100%;

		background-image: url("/assets/images/circuitry-orange.svg");
		background-repeat: no-repeat;

		transform: scaleX(-1);

		@include _system.to("9") {
			display: none;
		}
	}
}
