@use "@littlemissrobot/sass-system" as _system;

// =============================================================================
// Block
// =============================================================================
.c-nav-submenu {
	position: relative;

	width: 100%;
}

// =============================================================================
// Elements
// =============================================================================
.c-nav-submenu__header {
	display: flex;
	justify-content: space-between;
	align-items: center;

	@include _system.to("9") {
		width: 100%;
	}
}

.c-nav-submenu__text {
	@include _system.typo(title, lg);

	color: _system.color(text, inverse);

	@include _system.at("9") {
		@include _system.typo(paragraph, lg, base);
	}
}

.c-nav-submenu__icon {
	color: _system.color(text, inverse);

	transform-origin: center;
	transition: transform 0.3s ease-in-out;
}

.c-nav-submenu__flyout {
	display: none;

	@include _system.at("9") {
		position: absolute;
		bottom: _system.rem(-20px);
		left: 0;

		display: block;
		width: 100%;
		min-width: _system.rem(250px);

		opacity: 0;

		transform: translateY(100%) translateY(-10px);
		transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

		pointer-events: none;
	}
}

// =============================================================================
// States
// =============================================================================
.c-nav-submenu {
	&.is-open {
		.c-nav-submenu__flyout {
			display: block;

			@include _system.at("9") {
				opacity: 1;

				transform: translateY(100%);

				pointer-events: auto;
			}
		}

		.c-nav-submenu__icon {
			transform: rotate(180deg);
		}
	}
}

// =============================================================================
// Scopes
// =============================================================================
.s-inverted {
	.c-nav-submenu__text,
	.c-nav-submenu__icon {
		color: _system.color(text, base);
	}
}
