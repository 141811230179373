@use "@littlemissrobot/sass-system" as _system;
@use "../settings" as settings;

// =============================================================================
// Block
// =============================================================================
.c-service {
	padding: _system.rem(24px);

	background-color: _system.color(background, base);
	border: settings.$borders_base;
}
// =============================================================================
// Elements
// =============================================================================
.c-service__title {
	@include _system.typo(title, md);

	color: _system.color(text, base);
}

.c-service__description {
	@include _system.typo(paragraph, lg);

	color: _system.color(text, light);
}

.c-service__case-label {
	@include _system.typo(title, sm);

	display: block;

	color: _system.color(text, base);
}
